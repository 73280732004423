.custom-dark-bg {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    padding: 5px;
}

.custom-red {
    font-style: italic !important;
    color: red;
}
